import $ from 'jquery';
import Cookies from 'js-cookie'

function getGlobalDarkmode() {
    if (typeof window.getDarkmode === "function") {
        return window.getDarkmode();
    }
    return "off";
}

function toggleTopbar() {
    const topbar = $('#topbar');
    const rgb = topbar.css("background-color");
    const colors = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    const brightness = parseInt(colors[1]) * 0.213 + parseInt(colors[2]) * 0.715 + parseInt(colors[3]) * 0.072;
    const isLight = brightness > 255 / 2;
    if (isLight) {
        topbar.removeClass("dark-background");
        topbar.addClass("light-background");
    } else {
        topbar.removeClass("light-background");
        topbar.addClass("dark-background");
    }
}

// Wir brauchen den Status im Backend um direkt die Klasse darkmode im Backend zu setzen.
// ansonsten sieht man die Seite ganz kurz im hellen Design und es wirkt wie ein Blitzlicht

window.activateDarkmode = function() {
    $("html").addClass("darkmode")
    Cookies.set("darkmodeAutoStatus", "on", {path: '/', expires: 28, secure: true, sameSite: "Lax"})
    toggleTopbar()
}

window.deactivateDarkmode = function () {
    $("html").removeClass("darkmode")
    Cookies.set("darkmodeAutoStatus", "off", {path: '/', expires: 28, secure: true, sameSite: "Lax"})
    toggleTopbar()
}

function colorScheme() {
    return window.matchMedia('(prefers-color-scheme: dark)')
}

function inIFrame() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

function resolveDarkmodeAuto() {
    if (colorScheme().matches) {
        activateDarkmode()
    } else {
        deactivateDarkmode()
    }
}

window.addEventListener('DOMContentLoaded', function () {
    if (!inIFrame()) {
        if (getGlobalDarkmode() === "on") {
            activateDarkmode()
        } else if (getGlobalDarkmode() === "auto") {
            colorScheme().addEventListener('change', resolveDarkmodeAuto);
            resolveDarkmodeAuto()
        } else {
            deactivateDarkmode()
        }
    }
})

